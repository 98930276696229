import { Container } from "@material-ui/core"
import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import queryString from "query-string"
import { Layout } from "../components/layout"
import styled from "styled-components"
import Breadcrumb from "../components/Breadcrumb"
import { Button } from "../components/Buttons/Buttons"
import { ErrorMessage } from "../components/Form/ErrorMessageText"
import { makeApiCall, testPasswordToken } from "../services/apiCall"
// import { makeApiCall } from "../services/auth"
import RoundTick from "../assets/forms/round-tick.svg"
import RoundCross from "../assets/forms/round-cross.svg"
import { encode } from "js-base64"

const ForgottenPassword = ({ data, location }) => {
  const [tokenApproved, setTokenApproved] = useState(false)
  const [tokenFailed, setTokenFailed] = useState(false)

  const [passwordValiation, setPasswordValidation] = useState({
    length: false,
    UpperLower: false,
    number: false,
    matching: false,
  })

  const [state, setState] = useState({
    password: "",
    confirmPassword: "",
  })
  const [errorState, setErrorState] = useState({
    password: "",
    confirmPassword: "",
  })
  const handlePasswordValidiation = e => {
    const { value, name } = e.target

    setState({ ...state, [e.target.name]: e.target.value })

    let validation = passwordValiation
    if (name === "password") {
      if (value.length >= 8) {
        validation = { ...validation, length: true }
      } else {
        validation = { ...validation, length: false }
      }

      if (value.search(/[a-z]/) >= 0 && value.search(/[A-Z]/) >= 0) {
        validation = { ...validation, UpperLower: true }
      } else {
        validation = { ...validation, UpperLower: false }
      }

      if (value.search(/[0-9]/) >= 0) {
        validation = { ...validation, number: true }
      } else {
        validation = { ...validation, number: false }
      }
      if (value === state.confirmPassword) {
        validation = { ...validation, matching: true }
      } else {
        validation = { ...validation, matching: false }
      }
    }
    if (name === "confirmPassword") {
      if (value === state.password) {
        validation = { ...validation, matching: true }
      } else {
        validation = { ...validation, matching: false }
      }
    }

    setPasswordValidation(validation)
  }

  useEffect(() => {
    let mount = true
    if (mount) {
      const { token } = queryString.parse(location.search)

      if (token) {
        const decoded = decodeURI(token)
        testPasswordToken(decoded).then(result => {
          if (result.success) {
            setTokenApproved(true)
          } else {
            setTokenFailed(true)
          }
        })
      } else {
        navigate("/login")
      }
    }
    return () => {
      mount = false
    }
  }, [])

  function handleSubmit() {
    //validate email
    if (
      !passwordValiation.UpperLower ||
      !passwordValiation.length ||
      !passwordValiation.matching ||
      !passwordValiation.number
    ) {
      setErrorState({
        ...errorState,
        password: "Please resolve the issues with your password.",
      })
      return
    }
    const { token } = queryString.parse(location.search)
    const decoded = decodeURI(token)

    makeApiCall(`/change-password?secret_token=${decoded}`, { password: encode(state.password) }).then(data => {
      if (data.success) {
        navigate("/forgotten-password-success")
      }
    })
  }

  const breadcrumbData = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: tokenApproved ? "Reset Password" : "Forgotten Password",
      url: "/forgotten-password",
    },
  ]

  return (
    <Layout title={"Graduate Fashion Week - Sign Out Sucessful"}>
      <div style={{ backgroundColor: "#FAFAFA", width: "100%" }}>
        <Container maxWidth={"lg"}>
          <Breadcrumb data={breadcrumbData} />
        </Container>
      </div>
      <StyledContainer maxWidth={"sm"}>
        {tokenApproved ? (
          <>
            <Heading>Password Reset</Heading>
            <SubHeading>Lets set you up with a new password</SubHeading>
            <label id={"password"}>Password</label>
            <input
              type={"password"}
              name={"password"}
              value={state.password}
              onChange={handlePasswordValidiation}
            ></input>
            <br />
            <br />
            <label>Your password must have</label>
            <Columns>
              <PasswordStrength>
                <p>{passwordValiation.length ? <RoundTickStyled /> : <RoundCrossStyled />}8 or more characters</p>
                <p>
                  {passwordValiation.number ? <RoundTickStyled /> : <RoundCrossStyled />}
                  At least one number
                </p>
              </PasswordStrength>
              <PasswordStrength>
                <p>
                  {passwordValiation.UpperLower ? <RoundTickStyled /> : <RoundCrossStyled />}
                  Upper and lower case letters
                </p>
                <p>
                  {passwordValiation.matching ? <RoundTickStyled /> : <RoundCrossStyled />}
                  Must match confirmation
                </p>
              </PasswordStrength>
              <div></div>
            </Columns>
            <br />
            <label>Confirm Password</label>
            <input
              type={"password"}
              name={"confirmPassword"}
              value={state.confirmPassword}
              onChange={handlePasswordValidiation}
            ></input>
            <ErrorMessage>{errorState.password !== "pass" ? errorState.password : " "}</ErrorMessage>
            <ButtonContainer>
              <div onClick={() => handleSubmit()} style={{ width: "100%" }}>
                <Button>Save New Password</Button>
              </div>
            </ButtonContainer>
          </>
        ) : (
          <>
            <Wrapper>
              <Heading>Password Reset</Heading>
              <SubHeading>Just checking everything is in order...</SubHeading>
            </Wrapper>
          </>
        )}
      </StyledContainer>
    </Layout>
  )
}

const StyledContainer = styled(Container)`
  padding-top: 120px;
  padding-bottom: 120px;
`

export default ForgottenPassword

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 85px auto 158px;
`

const Heading = styled.h2`
  font-size: 50px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 55px;
  text-align: center;
`

const SubHeading = styled.h3`
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
`

const ButtonContainer = styled.div`
  margin-bottom: 15px;
`

const PasswordStrength = styled.div`
  > p {
    margin-bottom: 16px;
    padding-bottom: 0px;
    line-height: 16px;
  }
`

const RoundTickStyled = styled(RoundTick)`
  height: 15px;
  width: 15px;
  margin-right: 10px;
  top: 3px;
  position: relative;
  fill: ${props => props.theme.colors.turquoise};
  color: ${props => props.theme.colors.turquoise};
`
const RoundCrossStyled = styled(RoundCross)`
  height: 15px;
  width: 15px;
  margin-right: 10px;
  top: 3px;
  position: relative;
`

const Columns = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  > div {
    flex: 1;
    + div {
      /* margin-left: 30px; */
      @media (max-width: ${props => props.theme.breakpoints.sm}) {
        margin-left: 0px;
      }
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
  }
`
